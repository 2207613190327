import { css } from 'lit';

// these styles can be imported from any component
// for an example of how to use this, check /pages/about-about.ts
export const styles = css`

  sl-card {
    width: 90%;
    margin: 5%;
  }

  @media(min-width: 1000px) {
    sl-card {
      width: 70vw;
    }
  }

  main {
    margin-top: 60px;
  }
`;