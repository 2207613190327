import { LitElement, css, html } from 'lit';
import { property, customElement } from 'lit/decorators.js';


import '@shoelace-style/shoelace/dist/components/card/card.js';
import '@shoelace-style/shoelace/dist/components/input/input.js';
import '@shoelace-style/shoelace/dist/components/badge/badge.js';
import '@shoelace-style/shoelace/dist/components/button/button.js';


import { styles } from '../styles/shared-styles';

const HOST = 'https://mbserver.mosquitobusters.eu/api';

interface Spray {
	SprayTime: string,
	SprayDuration: string,
	SprayPressure: string,
	SprayType: string
}

@customElement('app-home')
export class AppHome extends LitElement {

	// For more information on using properties and state in lit
	// check out this link https://lit.dev/docs/components/properties/
	@property() message = 'Home';

	@property() version = '0.0.9';

	@property() mbid: string | null = localStorage.getItem('siteID');
	@property() name: string | null = localStorage.getItem('name');

	@property() sprayHistory: Spray[] = [];
	@property() lastSpray: Spray | null = null;

	@property() sprayButtonIsLoading = false;
	@property() sprayButtonIsSpraying = false;

	@property() skipButtonIsLoading = false;
	@property() skipButtonIsSkipping = false;

	@property() connectButtonIsLoading = false;

	@property() connectionError = false;

	static get styles() {
		return [
			styles,
			css`
      #welcomeBar {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      #welcomeCard,
      #infoCard {

      }

      #mainCard h2, #mainCard p, #infoCard h2, #infoCard p, #connectionCard h2, #connectionCard p {
        margin-top: 0;
        margin-bottom: 0.5rem;
      }

	  #infoCard h2 {
		margin-bottom: 0;
	  }

	  #infoCard p {
		font-size: .8rem;
	  }

      sl-card::part(footer) {
        display: flex;
        justify-content: flex-end;
      }

      @media(min-width: 750px) {
        sl-card {
          width: 70vw;
        }
      }


      @media (horizontal-viewport-segments: 2) {
        #welcomeBar {
          flex-direction: row;
          align-items: flex-start;
          justify-content: space-between;
        }

        #welcomeCard {
          margin-right: 64px;
        }
      }

	  #appVersion {
		color: rgba(0,0,0,.45);
	  }
    `];
	}

	constructor() {
		super();
	}

	async firstUpdated() {
		// this method is a lifecycle even in lit
		// for more info check out the lit docs https://lit.dev/docs/components/lifecycle/
		this.refreshHistory();
	}

	async spray() {
		this.sprayButtonIsLoading = true;
		try {

			await fetch(`${HOST}/units/${this.mbid}/actions/perform-spray`);

			this.sprayButtonIsSpraying = true;

			setTimeout(() => {
				this.sprayButtonIsSpraying = false;
				this.refreshHistory();
			}, 55 * 1000)


		} catch (e) {
			window.alert(e instanceof Error ? e.message : e);
		} finally {
			this.sprayButtonIsLoading = false;
		}
	}

	async skip() {
		this.skipButtonIsLoading = true;
		try {

			await fetch(`${HOST}/units/${this.mbid}/actions/skip-next-spray`);

			this.skipButtonIsSkipping = true;

			this.refreshHistory();

		} catch (e) {
			window.alert(e instanceof Error ? e.message : e);
		} finally {
			this.skipButtonIsLoading = false;
		}
	}

	async refreshHistory() {

		if (!this.isConnectedToServer()) {
			return;
		}

		const response = await fetch(`${HOST}/units/${this.mbid}/actions/history`);

		if (response.status !== 200) {
			throw new Error('Can not fetch history: ' + response.status);
		}

		this.sprayHistory = await response.json();

		if (this.sprayHistory.length > 0) {
			this.lastSpray = this.sprayHistory[0];
		}
	}

	getLastSprayText() {
		if (this.sprayButtonIsSpraying) {
			return 'Currently performing spray';
		}
		if (this.lastSpray) {
			const sprayTime = this.lastSpray.SprayTime.replace(/:\d\d$/, '');
			return `${sprayTime}`;
		}
		return 'Loading ...';
	}

	getLastSprayDetails() {
		if (this.sprayButtonIsSpraying) {
			return '';
		}

		if (this.lastSpray) {

			return `${this.lastSpray.SprayType}, ${this.lastSpray.SprayPressure} psi, ${this.lastSpray.SprayDuration}s`;
		}
		return '';
	}

	isConnectedToServer() {
		return typeof this.mbid === 'string';
	}

	async connect(ev: Event) {
		ev.preventDefault();
		ev.stopPropagation();

		try {
			const idInputEl = this.shadowRoot!.getElementById('mbid-input')! as HTMLInputElement;
			const nameInputEl = this.shadowRoot!.getElementById('name-input')! as HTMLInputElement;


			const mbid = idInputEl.value.trim().toLowerCase();
			const name = nameInputEl.value.trim().toLowerCase();



			const response = await fetch(`${HOST}/units/${mbid}/actions/connect?name=${encodeURI(name)}`);

			const result = await response.json();

			if (result.found && result.auth) {
				// Set:
				this.mbid = mbid;
				this.name = name;

				// Store:
				localStorage.setItem('siteID', this.mbid);
				localStorage.setItem('name', this.name);

				this.connectionError = false;
				this.refreshHistory();
			} else {
				if (result.found) {
					// assert(!result.auth)
					throw new Error("Customer name not found");
				} else {
					throw new Error("Unit (Site ID) not found");
				}
			}

		} catch (e) {

			this.mbid = null;
			this.connectionError = true;
			console.error(e);
			window.alert(e);
		}


	}

	disconnect() {
		this.mbid = null;
	}

	render() {
		return html`
      		<app-header></app-header>

			<main>
				<div id="welcomeBar">

					${this.renderMain()}

					${this.renderHistory()}

					${this.renderConnection()}

					<p id="appVersion" style="font-size: .8rem; margin-bottom: 2rem">
						Version: ${this.version}
					</p>
				</div>
			</main>
    	`;
	}

	renderMain() {
		if (this.isConnectedToServer()) {
			return html`
				<sl-card id="mainCard">
					<div slot="header">
					<h2>Last spray</h2>
					<p>
						<b>${this.getLastSprayText()}</b>
						<br>
						${this.getLastSprayDetails()}
					</p>

					</div>
					<sl-button variant="success" @click="${this.spray}" ?loading=${this.sprayButtonIsLoading} ?disabled=${this.sprayButtonIsLoading || this.sprayButtonIsSpraying} style="width: 100%; margin-bottom:1rem;">${this.sprayButtonIsSpraying ? 'Performing spray...' : 'Perform spray'}</sl-button>
					<sl-button @click="${this.skip}" ?loading=${this.skipButtonIsLoading} ?disabled=${this.skipButtonIsLoading || this.skipButtonIsSkipping}  style="width: 100%">${this.skipButtonIsSkipping ? 'Skipping next spray...' : 'Skip next spray'}</sl-button>

				</sl-card>
			`;
		} else {
			return undefined;
		}
	}

	renderHistory() {
		if (this.isConnectedToServer()) {
			return html`
				<sl-card id="infoCard">
					<div slot="header">
						<h2>History</h2>
					</div>
					${this.sprayHistory.slice(0, 10).map(spray =>
						html`
							<p>
								<b>${spray.SprayTime.replace(/:\d\d$/, '')}</b><br>
								${spray.SprayType}, ${spray.SprayPressure} psi, ${spray.SprayDuration}s
							</p>
						`
					)}

				</sl-card>
			`;
		} else {
			return undefined;
		}
	}

	renderConnection() {
		return html`
			<sl-card id="connectionCard">
				<div slot="header">

				<h2>Connection</h2>

				${
					this.connectionError ?
					html`
						<p style="color: red;">
							Failed to connect, please try again.<br>
						</p>
						<p>
							Make sure that:
							<ul>
								<li>the Site ID is correct</li>
								<li>the customers name is correct</li>
								<li>the unit has internet access</li>
								<li>the unit is connected to the server</li>
							</ul>
						</p>
					`
					:
					html`
						<p>
							${this.isConnectedToServer() ? `Connected to ${this.mbid}` : 'Setup your connection'}
						</p>
					`
				}


				</div>
				${
					this.isConnectedToServer() ?
					html`
						<sl-button @click="${this.disconnect}" style="width: 100%">Disconnect</sl-button>
					`
					:
					html`
						<form @submit="${this.connect}">
							<sl-input id="mbid-input" value="${localStorage.getItem('siteID')}" required minlength="1" placeholder="Site ID" style="margin-bottom: 0.8rem" label="Enter your Site ID"></sl-input>
							<sl-input id="name-input" value="${localStorage.getItem('name')}" required minlength="2" placeholder="Name" style="margin-bottom: 1.25rem" label="Enter your first and/or lastname"></sl-input>
							<sl-button type="submit" variant="primary" ?loading=${this.connectButtonIsLoading} style="width: 100%">${this.connectButtonIsLoading ? 'Connecting...' : 'Connect'}</sl-button>
						</form>
						`
				}
			</sl-card>
		`;
	}


}

